import React from "react"
import { Layout, SEO } from "@global"
import { Wysiwyg } from "@layouts"

const ComponentsPage = () => (
  <Layout>
    <SEO title="Components" />
    <div className="py-10">
      <Wysiwyg />
    </div>
  </Layout>
)

export default ComponentsPage
